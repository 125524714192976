import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import { Styling } from "../styles/pages/alumni.styled";
import { SplitStyling } from "../styles/pages/default-split.styled";
import Arrow from "../images/arrow_down_right.inline.svg";
import { handleLogin, isLoggedIn, isAlumni } from "../utils/auth";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import gsap from "gsap";
import axios from "axios";
import Seo from "../components/global/seo";

export default function Alumni({ data }) {
  let rememberedUser;
  if (typeof window !== "undefined") {
    rememberedUser = window?.localStorage?.rememberUser;
  } else {
    rememberedUser = undefined;
  }

  const [username, setUsername] = useState(rememberedUser);
  const [password, setPassword] = useState();
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [resetEmail, setResetEmail] = useState();
  // const [isAlumni, setIsAlumni] = useState(false)
  const [validationCode, setValidationCode] = useState();
  const [resetPassword, setResetPassword] = useState();
  const [remember, setRemember] = useState(false);
  const baseUrl = "https://admin.toolroomacademy.com/wp-json/bdpwr/v1/";
  const resetPasswordUrl = baseUrl + "reset-password";
  const validateCodeUrl = baseUrl + "validate-code";
  const setNewPasswordUrl = baseUrl + "set-password";

  function reset(resetEmail) {
    if (typeof window !== "undefined") {
      axios
        .post(resetPasswordUrl, {
          email: resetEmail,
        })
        .then((r) => {
          //console.log(r.data);
          setCurrentSlideIndex(1);
        })
        .catch((e) => {
          //console.log(e);
          alert(
            "Whoops, something went wrong. Please contact cal@toolroomacademy.com for further support."
          );
        });
    }
  }

  const handleUpdateResetEmail = (event) => {
    setResetEmail(event.target.value);
  };

  const handleResetAndTransition = () => {
    reset(resetEmail);
  };

  useEffect(() => {
    gsap.to(".alumni  .reset-form .form-styles", {
      x: `-${150 * currentSlideIndex}%`,
    });
  }, [currentSlideIndex]);

  useEffect(() => {
    gsap.to(".set-password .reset-form .form-styles", {
      x: `-${150 * currentSlideIndex}%`,
    });
  }, [currentSlideIndex]);

  return (
    <Styling>
      <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} />

      <SplitStyling>
        <div className="left image-wrapper">
          <GatsbyImage
            image={getImage(data?.wpPage?.alumniFields?.alumniFormImage?.localFile)}
            className="image"
            alt={"test"}
          />
        </div>
        <div className="right set-password">
          <div className="right-inner">
            <h2 className="main_title">
              <Arrow /> Set your password
            </h2>
            <div className="form-text">
              <p>Please follow the steps below to set your account password</p>
            </div>
            <div className="forms-wrapper">
              <div className="reset-form fakeForm-b">
                <div className="form-styles">
                  <div className="form-step-1-b form-step">
                    <input
                      type="text"
                      placeholder="Email address"
                      onChange={(e) => handleUpdateResetEmail(e)}
                      className="reset-email col-1"
                    />
                    <button
                      onClick={() => handleResetAndTransition()}
                      className="send-reset-form submit_button capsule_button black col-1">
                      Request password reset
                    </button>
                  </div>
                  <div className="form-step-2-b form-step">
                    <p>
                      Thanks! You've now been send your password reset email. Please check your spam
                      folder if you dont receive it after a few minutes.
                    </p>
                    <p>You can now close this window.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SplitStyling>
    </Styling>
  );
}

export const query = graphql`
  {
    wpPage(title: { eq: "Alumni" }) {
      id
      seo {
        metaDesc
        title
      }
      alumniFields {
        alumniText
        alumniTextBeforeLoginForm
        alumniFormImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  }
`;
